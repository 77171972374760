import React from 'react';

import { ProductGrid } from '@/components/menu/ProductGrid/ProductGrid';
import { UpsellsSkeletonContainer } from '@/components/skeleton/UpsellsSkeletonContainer/UpsellsSkeletonContainer';
import type { ProductModelType } from '@/models/api';
import { useStore } from '@/models/root-store';

import UpsellMobileFooter from '../UpsellMobileFooter/UpsellMobileFooter';

export type UpsellsProductMobileContainerType = {
  productsList: ProductModelType[] | undefined;
  refreshUpSells?: () => void;
  onRequestClose: () => void;
  loading: boolean;
};

const UpsellsProductMobileContainer: React.FC<
  UpsellsProductMobileContainerType
> = ({ productsList, onRequestClose, loading }) => {
  const store = useStore();
  const {
    cartStore: { draftCart },
    cartStore,
  } = store;
  const { cartToUse } = cartStore;
  const { menuBuilderPlan } = cartToUse;
  const { action, selectedPlan } = draftCart;
  const planRoute: string = selectedPlan?.planRoute || '';
  const handleSwap = selectedPlan?.swapLineItem;
  const selectedItemPosition = selectedPlan?.selectedItemPosition;
  if (loading) {
    return <UpsellsSkeletonContainer upsellMobileView={true} />;
  }
  return (
    <div className="z-[80] flex h-full flex-col justify-between">
      <div className="overflow-auto p-6 pb-24">
        <ProductGrid
          action={action}
          products={productsList}
          orderPlan={menuBuilderPlan}
          handleSwap={handleSwap}
          selectedItemPosition={selectedItemPosition}
          planRoute={planRoute}
          planIdToView={draftCart?.planIdToView}
          mode={draftCart.mode}
          upsellView={false}
        />
      </div>
      <UpsellMobileFooter onRequestClose={onRequestClose} />
    </div>
  );
};

export default UpsellsProductMobileContainer;
