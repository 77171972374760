import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';

import CloseIcon from '@/components/icons/CloseIcon';
import { SkeletonNavLink } from '@/components/skeleton/SkeletonNavLink/SkeletonNavLink';
import { useMediaQuery } from '@/hooks';
import type {
  MenuHierarchyRecordModelType,
  NavbarLinkExtraRecordModelType,
  NavbarLinkRecordModelType,
  NavbarRecordModelType,
} from '@/models/api';
import { useStore } from '@/models/root-store';

import DiscoveryBanner from '../DiscoveryBanner/DiscoveryBanner';
import ExtraLink from '../ExtraLink/ExtraLink';
import MobileCategories from '../MobileCategories/MobileCategories';
import MobileNavLinks from '../MobileNavLinks/MobileNavLinks';

interface MegaNavMobileProps {
  isOpen: boolean;
  onClick: () => void;
  categories: Array<MenuHierarchyRecordModelType>;
  navLinks: NavbarRecordModelType | undefined;
}

const NavigationMobileLogout = dynamic(
  () =>
    import(
      '@/components/common/Nav/NavigationMobileLogout/NavigationMobileLogout'
    ),
  { ssr: false }
);

const MegaNavMobile = React.forwardRef(
  (
    { isOpen, onClick, categories, navLinks }: MegaNavMobileProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const router = useRouter();
    const {
      customerStore: { isLoggedIn },
    } = useStore();
    const border = isLoggedIn ? 'mb-3 py-4 border-y' : 'border-t pb-2';
    const margin = isLoggedIn ? 'mt-4' : 'mt-3';

    const [selectedCategory, setSelectedCategory] = useState<
      MenuHierarchyRecordModelType | undefined
    >(undefined);

    const [parentCategory, setParentCategory] = useState<
      MenuHierarchyRecordModelType | undefined
    >(undefined);

    const [selectedLink, setSelectedLink] = useState<
      NavbarLinkRecordModelType | undefined
    >(undefined);

    const isTablet = useMediaQuery(`(min-width:640px)`);
    const bgImage = isTablet
      ? "bg-[url('https://www.datocms-assets.com/81001/1702248577-nav-bg-tablet.png')]"
      : "bg-[url('https://www.datocms-assets.com/81001/1702950672-bg-m-nav.png')]";

    const onRequestClose = () => {
      if (selectedCategory) {
        setSelectedCategory(parentCategory || undefined);
        setParentCategory(undefined);
      } else if (selectedLink) {
        setSelectedLink(undefined);
      } else {
        onClick();
      }
    };

    const renderHeader = () => {
      if (selectedCategory) {
        return (
          <div className="flex justify-between">
            <span className="pt-1 font-interSemibold text-xl leading-5">
              {selectedCategory?.name}
            </span>
            <div role="button" className="pr-4" onClick={onClick}>
              <CloseIcon />
            </div>
          </div>
        );
      }

      if (selectedLink) {
        return (
          <div className="flex justify-between">
            <span className="pt-1 font-interBold text-xl leading-5">
              {selectedLink?.text}
            </span>
            <div role="button" className="pr-4" onClick={onClick}>
              <CloseIcon />
            </div>
          </div>
        );
      }
      return (
        <div role="button" className="flex justify-end pr-4" onClick={onClick}>
          <CloseIcon />
        </div>
      );
    };

    const renderCloseIcon = () => {
      if (selectedCategory || selectedLink) {
        return (
          <Image
            width={30}
            height={30}
            src="https://www.datocms-assets.com/81001/1701651898-back_arrow.svg"
            alt=""
          />
        );
      }
      return <div />;
    };

    const { extraLinks } =
      navLinks && navLinks.links && navLinks.links.length > 0
        ? navLinks.links[0]
        : [];

    useEffect(() => {
      if (selectedCategory && !selectedCategory.childrenCategories && isOpen) {
        onClick();
        setSelectedCategory(undefined);
      }
    }, [selectedCategory, selectedLink]);

    useEffect(() => {
      if (isOpen) {
        onClick();
      }
    }, [router.asPath]);

    useEffect(() => {
      if (isOpen) {
        setSelectedCategory(undefined);
        setSelectedLink(undefined);
      }
    }, [isOpen]);

    return (
      <SlidingPane
        className="mobile-mega-nav h-full !w-[90%] justify-between"
        from={'left'}
        overlayClassName="z-50"
        isOpen={isOpen}
        title={renderHeader()}
        closeIcon={renderCloseIcon()}
        onRequestClose={onRequestClose}
        onAfterOpen={() => setSelectedCategory(undefined)}
      >
        <div
          style={{
            transition: 'all 250ms ease',
            transform: `translateX(${isOpen ? '0%' : '-100%'})`,
            background: 'rgba(0,0,0,0.5)',
          }}
          className="right-0 z-50 flex size-full bg-white"
        >
          <div ref={ref} className="w-full bg-white">
            <div className="px-8 py-5 mobile-425:px-4">
              {!navLinks && (
                <div className="m-auto flex h-screen">
                  <SkeletonNavLink />
                </div>
              )}
              {!selectedLink && (
                <MobileCategories
                  categories={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setParentCategory={setParentCategory}
                  onClick={onClick}
                />
              )}

              {/* Primary Links */}
              {!selectedCategory && (
                <MobileNavLinks
                  navLinks={navLinks}
                  selectedLink={selectedLink}
                  setSelectedLink={setSelectedLink}
                  onClick={onClick}
                />
              )}
              <div
                className={`-mx-1 mt-8 flex flex-wrap ${
                  isLoggedIn ? 'gap-4' : 'gap-2 mobile-375:gap-3'
                }`}
              >
                {!selectedCategory &&
                  !selectedLink &&
                  extraLinks?.map(
                    (
                      extraLink: NavbarLinkExtraRecordModelType,
                      index: number,
                      array: NavbarLinkExtraRecordModelType[]
                    ) => {
                      const isLastItem = index === array.length - 1;
                      const widthClass =
                        isLastItem && !extraLink.onlyForLoggedIn && isLoggedIn
                          ? 'w-full p-1'
                          : `  ${
                              isLoggedIn
                                ? 'mobile-375:w-[46.8%] w-[47.5%] md:w-[48.7%]'
                                : 'mobile-375:w-[47.5%] w-[48.5%]'
                            }`;

                      if (extraLink.onlyForLoggedIn && !isLoggedIn) {
                        return null;
                      }

                      return (
                        <div
                          key={extraLink.text}
                          className={`px-1 ${widthClass}`}
                        >
                          <ExtraLink
                            extraLink={extraLink}
                            onClick={onClick}
                            isLoggedIn={isLoggedIn}
                          />
                        </div>
                      );
                    }
                  )}
              </div>
            </div>

            {!selectedCategory && !selectedLink && (
              <div
                className={`static bottom-8 justify-end ${margin} w-full px-8 pb-8 pt-5  md:pb-0 mobile-425:px-4`}
              >
                <div className={`${border} border-grey-liter`}>
                  <NavigationMobileLogout />
                </div>

                {/* Secondary Links */}
                <ul className="py-4 font-interMedium">
                  {navLinks?.links?.map((data, index) => {
                    return (
                      <div key={index}>
                        {data.linkType !== 'Primary' && (
                          <Link
                            href={data.relativePathLink || data.externalUrl}
                            key={data.relativePathLink.substring(1)}
                            legacyBehavior
                          >
                            {(data.relativePathLink || data.externalUrl) && (
                              <a
                                className={`flex items-center border-bean leading-[16px]`}
                              >
                                <li className="flex items-center pb-5 text-base">
                                  {data.text}
                                </li>
                              </a>
                            )}
                          </Link>
                        )}
                      </div>
                    );
                  })}
                </ul>
                {navLinks?.links && (
                  <div className="mb-8">
                    <DiscoveryBanner
                      banner={navLinks?.links[0]?.discoveryBanner}
                    />
                  </div>
                )}
                <div className="py-4">
                  <div
                    className={`sticky rounded-xl bg-oat bg-cover bg-right-top bg-no-repeat mobile-320:bg-none ${bgImage}`}
                  >
                    <div className="flex flex-col gap-8 p-5 md:flex-row md:items-center">
                      <h4 className="max-w-[137px] font-interBold leading-5 text-black md:max-w-full">
                        Order in seconds through our app
                      </h4>
                      <div className="flex flex-col gap-2 md:flex-row">
                        <div className="cursor-pointer">
                          <Link href="https://mymusclechef.app.link/gdP0h3w5dHb">
                            <Image
                              src="/assets/images/svg/mymc-appstore.svg"
                              alt="app-store"
                              width={isTablet ? 95 : 127}
                              height={42}
                              className="rounded-l-lg md:min-h-[32px]"
                            />
                          </Link>
                        </div>
                        <div className="cursor-pointer">
                          <Link href="https://mymusclechef.app.link/nFt100M5dHb">
                            <Image
                              src="/assets/images/svg/mymc-googleplay.svg"
                              alt="play-store"
                              width={isTablet ? 115 : 127}
                              height={42}
                              className="rounded-l-lg md:max-h-[32px]"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </SlidingPane>
    );
  }
);

MegaNavMobile.displayName = 'MegaNavMobile';

export default MegaNavMobile;
