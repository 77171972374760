import Image from 'next/legacy/image';
import { useState } from 'react';

import BlurbStructuredText from '../StructuredText/BlurbStructuredText/BlurbStructuredText';

export interface ISeoBlurb {
  blurb?: any;
}

const SeoBlurb: React.FC<ISeoBlurb> = ({ blurb }) => {
  const [dropdown, setDropdown] = useState(false);

  const isBlurbEmpty =
    blurb.value.document.children
      .find((child: any) => child.type === 'paragraph')
      .children.find((child: any) => child.type === 'span').value === '';

  if (isBlurbEmpty) return null;
  return (
    <div className="my-12">
      <section className="mx-auto flex max-w-[80%] justify-center text-center font-inter text-base text-[#707274] md:max-w-[75%]">
        <p>{blurb?.value?.document?.children[0]?.children[0].value}</p>
        <span
          className={`${
            dropdown ? '-rotate-180' : '-rotate-0'
          } -mt-1 h-8 min-w-[32px] cursor-pointer`}
          onClick={() => setDropdown(!dropdown)}
        >
          <Image
            src="/assets/icons/chevron-down-mid.svg"
            alt="chevron-down-mid"
            width={32}
            height={32}
          />
        </span>
      </section>
      {dropdown && <BlurbStructuredText content={blurb} />}
    </div>
  );
};

export default SeoBlurb;
