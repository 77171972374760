import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';

const SubscriptionBar = observer(() => {
  const { cartStore } = useStore();
  const { draftCart } = cartStore;
  const router = useRouter();
  const isAccountPage = router.pathname === '/account';
  const isSwapPage = router.pathname === '/swap';
  const hideNav =
    draftCart &&
    draftCart.mode === Mode.EDIT_SUBSCRIPTION &&
    !isAccountPage &&
    !isSwapPage;

  const handleBack = () => {
    router.push({
      pathname: '/account',
      query: {
        cartOpen: true,
        ...(draftCart.mode && { mode: draftCart.mode }),
      },
    });
  };

  const pdp = router.pathname.includes('/products');
  const justify = pdp ? 'justify-center' : 'justify-start';

  return (
    <div
      className={`container sticky top-0 z-50 h-[64px] flex-row bg-black p-4 font-interMedium text-white lg:h-12 ${justify} ${
        hideNav ? 'flex' : 'hidden'
      }`}
    >
      <div className={pdp ? 'hidden' : 'flex w-1/5 items-center md:w-2/5'}>
        <div className="pr-3">
          <button
            onClick={() => handleBack()}
            id="close"
            className="flex items-center"
          >
            <Image
              id="back"
              src="https://www.datocms-assets.com/81001/1706494214-arrow_back.svg"
              className="cursor-pointer"
              alt="back"
              width={15}
              height={15}
            />
          </button>
        </div>

        <Link
          href={{
            pathname: '/account',
            query: {
              mode: Mode.EDIT_SUBSCRIPTION,
              cartOpen: true,
            },
          }}
          legacyBehavior
        >
          <div className="hidden w-auto cursor-pointer pl-4 text-lg md:flex">
            Back to Subscription
          </div>
        </Link>
      </div>

      <div className="my-auto whitespace-nowrap text-center text-sm	">
        Adding items to your subscription
      </div>
    </div>
  );
});
export default SubscriptionBar;
