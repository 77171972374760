import { SignedIn, SignedOut, UserButton } from '@clerk/nextjs';
import { FeaturesReady } from '@growthbook/growthbook-react';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import AuthModal from '@/components/auth/AuthModal/AuthModal';
import CartSlidingPane from '@/components/cart/CartSlidingPane/CartSlidingPane';
import MegaNavMobile from '@/components/common/MegaNav/MegaNavMobile/MegaNavMobile';
import NavLink from '@/components/common/MegaNav/NavLink/NavLink';
import MenuBar from '@/components/common/Nav/MenuBar/MenuBar';
import CartIcon from '@/components/icons/CartIcon';
import UserIcon from '@/components/icons/UserIcon';
import { SkeletonNavLink } from '@/components/skeleton/SkeletonNavLink/SkeletonNavLink';
import { useAuthModal } from '@/hooks/useAuthModal';
import staticLinks from '@/mock-data/meganav-data.json';
import type { NavbarLinkRecordModelType } from '@/models/api';
import { useStore } from '@/models/root-store';
import {
  AUTH_NAVIGATION_TARGET,
  AUTH_NAVIGATION_TARGET_KEEP_PATHNAMES,
} from '@/shared/constant';
import { Mode } from '@/utils/constants';

import { ImpersonationBanner } from '../ImpersonationBanner/ImpersonationBanner';
import SubLinksContainer from './SubLinksContainer/SubLinksContainer';

const CartNumber = dynamic(
  () => import('@/components/common/Nav/CartNumber/CartNumber'),
  { ssr: false },
);

// const UserSlide = dynamic(
//   () => import('@/components/common/Nav/UserSlide/UserSlide'),
//   { ssr: false }
// );

export type NavbarProps = {
  menuBar: boolean;
  setMenuBar: (menuBar: boolean) => void;
  setIsBlur: (isBlur: boolean) => void;
};

const MegaNav: React.FC<NavbarProps> = observer((props: NavbarProps) => {
  const {
    cartStore,
    customerStore: { isLoggedIn },
    generalStore: { navLinks, categories, chatReady },
  } = useStore();
  const { cart, draftCart } = cartStore;
  const { openCart } = cart;
  const [isAuthModalOpen, setIsAuthModalOpen] = useAuthModal();

  const router = useRouter();

  const openMSChat = () => {
    if (window.lcw && chatReady) {
      window.Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
    }
  };

  const isAccountPage = router.pathname === '/account';
  const hideNav =
    draftCart && draftCart.mode === Mode.EDIT_SUBSCRIPTION && !isAccountPage;

  const authNavTarget = React.useMemo(() => {
    const keepPath = AUTH_NAVIGATION_TARGET_KEEP_PATHNAMES.some((path) =>
      router.pathname.includes(path),
    );
    return keepPath ? window.location.pathname : '/account';
  }, [window.location.pathname]);

  return (
    <div
      id="target-container"
      className={hideNav ? 'hidden' : ' sticky top-0 z-50 '}
    >
      <ImpersonationBanner />
      <nav className="relative z-50 mx-auto flex flex-wrap items-center justify-between border-b border-off-lite bg-white">
        <div className="container mx-0 flex items-center justify-between px-0 lg:mx-auto lg:px-8">
          <MenuBar
            value={false}
            onClick={() => props.setMenuBar(!props.menuBar)}
          />
          <Link
            href="/"
            className="relative flex w-full justify-between md:w-auto md:justify-center lg:static lg:block lg:w-auto lg:justify-start"
          >
            <Image
              src={staticLinks.logo?.url}
              alt={staticLinks.logo?.alt}
              width={52}
              height={27}
              className="ml-7 block cursor-pointer lg:hidden"
            />
            <Image
              src={staticLinks.logo?.url}
              alt={staticLinks.logo?.alt}
              width={77}
              height={39}
              className="hidden cursor-pointer lg:block"
            />
          </Link>
          <ul
            className="mt-4 hidden flex-wrap justify-between space-x-8 px-4 text-base text-black sm:mt-0 lg:flex"
            onMouseOver={() => {
              props.setIsBlur(true);
            }}
            onMouseOut={() => {
              props.setIsBlur(false);
            }}
          >
            {!navLinks && <SkeletonNavLink />}

            {navLinks?.links?.map(
              (l: NavbarLinkRecordModelType, i: React.Key) => {
                return l.mobileOnly ? null : (
                  <NavLink key={i} navLink={l}>
                    {((l.subLinks && l.subLinks.length > 0) || l.fullMenu) && (
                      <div className="overflow-y-scroll">
                        <SubLinksContainer
                          navLink={l}
                          categories={categories}
                          isLoggedIn={isLoggedIn}
                        />
                      </div>
                    )}
                  </NavLink>
                );
              },
            )}
          </ul>
          <div className="flex-1 text-sm text-white lg:flex-none">
            <ul className="flex justify-end lg:justify-between">
              {chatReady && (
                <li className="mx-2 h-16 md:mx-3">
                  <button
                    className="relative flex h-16 w-10 shrink-0 cursor-pointer items-center justify-center"
                    onClick={openMSChat}
                  >
                    <Image
                      src="https://www.datocms-assets.com/81001/1707449791-chat-icon.svg"
                      width={24}
                      height={24}
                      alt="Chat"
                    />
                  </button>
                </li>
              )}
              <li className="user-account relative mr-1 h-16 lg:mr-3">
                <div className="relative flex h-16 cursor-pointer items-center justify-center">
                  <SignedIn>
                    {/* Mount the UserButton component */}
                    <UserButton
                      userProfileUrl="/account"
                      userProfileMode="navigation"
                    />
                  </SignedIn>
                  <SignedOut>
                    {/* Signed out users get sign in button */}
                    <div
                      onClick={() => {
                        sessionStorage.setItem(
                          AUTH_NAVIGATION_TARGET,
                          authNavTarget,
                        );
                        router.push('/signin');
                      }}
                    >
                      <UserIcon width={24} height={24} />
                    </div>
                  </SignedOut>
                </div>
              </li>
              <li className="mx-2 h-16 lg:mx-3">
                <button
                  className="relative flex h-16 cursor-pointer items-center justify-center"
                  onClick={openCart}
                >
                  <CartIcon width={24} height={24} />
                  <CartNumber />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <MegaNavMobile
        isOpen={props.menuBar}
        onClick={() => props.setMenuBar(!props.menuBar)}
        categories={categories}
        navLinks={navLinks}
      />
      <FeaturesReady timeout={2000}>
        <CartSlidingPane />
      </FeaturesReady>
      <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={() => setIsAuthModalOpen(false)}
        navigatedFrom="navbar"
      />
    </div>
  );
});
export default MegaNav;
