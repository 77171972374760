import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import React, { useEffect, useRef, useState } from 'react';

import CartFooter from '@/components/cart/CartFooter/CartFooter';
import { ClearCartModal } from '@/components/cart/ClearCartModal/ClearCartModal';
import EmptyCart from '@/components/cart/EmptyCart/EmptyCart';
import GoalBasedPlan from '@/components/cart/GoalBasedPlan/GoalBasedPlan';
import OrderSummary from '@/components/cart/OrderSummary/OrderSummary';
import TopButtons from '@/components/cart/TopButtons/TopButtons';
import WlpModal from '@/components/cart/WlpModal/WlpModal';
import { OtherProductsContainer } from '@/components/common/OtherProductsContainer/OtherProductsContainer';
import type { ProductModelType } from '@/models/api';
import { PlanCategory } from '@/models/api';
import type { OrderPlan } from '@/models/cart/order-plan';
import { useStore } from '@/models/root-store';
import { PLAN_ROUTE_MAP } from '@/utils/api-constants';
import { FEATURE_FLAGS, Mode } from '@/utils/constants';
import { trackRecommendationViewed } from '@/utils/track/tracker.helper';

import CustomPlan from '../CustomPlan/CustomPlan';
import GiftCardContainer from '../GiftCardContainer/GiftCardContainer';

export type CartType = {
  closeCart: () => void;
  checkoutOrSave: () => void;
  openMealPlan: () => void;
  MinOrderValue: number;
  loading: boolean;
  variation?: boolean;
  upsells?: ProductModelType[] | undefined;
  refreshUpSells: () => void;
  cartContainerRef?: React.RefObject<HTMLDivElement>;
};

const Cart = observer(
  ({
    closeCart,
    checkoutOrSave,
    openMealPlan,
    MinOrderValue,
    loading,
    variation,
    upsells = [],
    cartContainerRef,
    refreshUpSells,
  }: CartType) => {
    const {
      cartStore,
      generalStore: { getContentByKey },
    } = useStore();
    const { cart, draftCart, cartToUse } = cartStore;
    const content = getContentByKey('upsell');
    const [showClearCartModal, setShowClearCartModal] = useState(false);
    const [wlpModal, setWlpModal] = useState(false);
    const [isControlUpsellEnabled, setIsControlUpsellEnabled] = useState(true);
    const variant = useFeatureFlagVariantKey('upsell-experiment');

    useEffect(() => {
      // assuming we do not show control upsell if new upsell is enabled
      const newUpsellEnabled = variant === FEATURE_FLAGS.NEW_UPSELLS;
      if (newUpsellEnabled) {
        setIsControlUpsellEnabled(false);
        if (cart.isCartOpen) {
          try {
            trackRecommendationViewed('upsell_experiment', 'cart', 'cart');
          } catch (error) {
            // ignore error
          }
        }
      }
    }, [variant]);

    const {
      menuBuilderPlan,
      subTotal,
      cartList,
      isEmpty,
      menuBuilderItems,
      mode,
    } = cartToUse;

    const trackUpsellViewCalled = useRef(false);

    useEffect(() => {
      const newUpsellEnabled = variant === FEATURE_FLAGS.NEW_UPSELLS;
      if (
        upsells.length > 0 &&
        !trackUpsellViewCalled.current &&
        !newUpsellEnabled
      ) {
        if (cart.isCartOpen) {
          try {
            trackRecommendationViewed(
              'cart_upsell',
              'upsell',
              'cart_container',
            );
          } catch (error) {
            // ignore error
          }
        }
        trackUpsellViewCalled.current = true;
      }
    }, [upsells, variant]);

    const router = useRouter();

    const orderPlans = cartList.filter(
      (list: any) => list.title === 'Order Plans',
    )[0]?.data;

    const giftItems = cartList.filter(
      (list: any) => list.title === 'Gift Cards',
    )[0]?.data;

    const clearCart = () => {
      if (router.asPath.includes('goalbased')) router.push('/');

      cart.clearCart();
    };

    const generatePlanRoute = (plan: OrderPlan) => {
      const goal = plan.attributes.find(
        (attr: any) => attr.name === 'goal',
      )?.value;
      const { category } = plan;
      if (
        category &&
        category === PlanCategory.WLP &&
        draftCart.mode === Mode.EDIT_SUBSCRIPTION
      ) {
        setWlpModal(true);
        return false;
      }
      const route =
        PLAN_ROUTE_MAP[category as keyof typeof PLAN_ROUTE_MAP] +
        (goal ? `/${goal}` : '');
      return route;
    };

    const handleEditPlan = async (planId: string) => {
      if (mode === Mode.EDIT_CART && draftCart.planIdToView !== planId) {
        cartStore.resetDraftCart();
      }
      const plan = cartToUse.plansLookup[planId];
      const planMode = mode === Mode.EDIT_SUBSCRIPTION ? mode : Mode.EDIT_CART;
      if (mode === Mode.EDIT_SUBSCRIPTION || !plan.planRoute) {
        if (generatePlanRoute(plan)) {
          plan.setPlanRoute(generatePlanRoute(plan));
        }
      }

      const { planRoute, category } = plan;

      if (!(mode === Mode.EDIT_SUBSCRIPTION && category === PlanCategory.WLP)) {
        closeCart();
      }
      await router.replace({
        pathname: planRoute,
        query: { mode: planMode, planId },
      });
      // This is if they try to edit on the same page
      if (router.pathname === planRoute) {
        router.reload();
      }
    };

    const handleRemovePlan = (planId: string) => {
      cart.removePlan(planId);
      draftCart.removePlan(planId);
    };

    const handleRemoveGift = (giftCardId: string) => {
      cart.removeGiftItem(giftCardId);
    };

    return (
      <>
        {isEmpty && <EmptyCart closeCart={closeCart} />}

        {!isEmpty && (
          <div className="flex h-full flex-col justify-between">
            <div className="relative overflow-y-auto" ref={cartContainerRef}>
              <OrderSummary cart={cartToUse} />
              <TopButtons
                closeCart={closeCart}
                mode={mode}
                openMealPlan={openMealPlan}
                isMenuPage={router.asPath.includes('menu')}
              />

              {/* Clear Cart */}
              {mode !== Mode.EDIT_SUBSCRIPTION ? (
                <div className="relative mt-8">
                  <p
                    className="absolute right-3 top-1 mr-4 cursor-pointer font-interMedium text-sm text-attention"
                    onClick={() => setShowClearCartModal(!showClearCartModal)}
                  >
                    Clear Cart
                  </p>
                </div>
              ) : (
                <div className="mt-8" />
              )}

              {/* Order Plans */}
              {orderPlans &&
                orderPlans.length > 0 &&
                orderPlans.map((plan: any) => (
                  <div key={plan.planId}>
                    <GoalBasedPlan
                      plan={plan}
                      handleEditPlan={handleEditPlan}
                      handleRemovePlan={handleRemovePlan}
                    />
                  </div>
                ))}

              {/* Menu Builder */}
              <CustomPlan
                categories={menuBuilderItems}
                plan={menuBuilderPlan}
              />

              {/* Gift Cards */}
              <GiftCardContainer
                giftItems={giftItems}
                handleRemoveGift={handleRemoveGift}
              />

              {isControlUpsellEnabled && !_.isEmpty(upsells) && (
                // eslint-disable-next-line tailwindcss/no-custom-classname
                <div className="cart-upsells flex bg-off-grey py-4">
                  <OtherProductsContainer
                    products={upsells}
                    title={content?.title || 'Grab an energy boost'}
                    refreshUpSells={refreshUpSells}
                  />
                </div>
              )}

              {/* mode !== Mode.EDIT_SUBSCRIPTION && <FreeShippingBanner /> */}

              <ClearCartModal
                isOpen={showClearCartModal}
                onRequestClose={() => setShowClearCartModal(false)}
                clearCart={clearCart}
              />
            </div>
            <CartFooter
              total={subTotal}
              MOV={MinOrderValue}
              cartMode={mode}
              loading={loading}
              checkoutOrSave={checkoutOrSave}
              variation={variation}
            />
          </div>
        )}
        <WlpModal
          isOpen={wlpModal}
          isClose={() => {
            setWlpModal(false);
          }}
        />
      </>
    );
  },
);

export default Cart;
